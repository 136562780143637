import React from 'react';

import { CdnFeaturesTabs } from '../../CdnFeaturesTabs/CdnFeaturesTabs';
import { CdnFeature } from './CdnFeature/CdnFeature';
import { HomeHeader } from './HomeHeader/HomeHeader';
import { HomeUseCases } from './HomeUseCases/HomeUseCases';
import { AnimatedPath } from '@components/AnimatedPath/AnimatedPath';
import { Button } from '@components/Button/Button';
import { ColumnsLayout } from '@components/ColumnsLayout/ColumnsLayout';
import { CtaPanel } from '@components/CtaPanel/CtaPanel';
import { FeaturedStat } from '@components/FeaturedStat/FeaturedStat';
import { Col } from '@components/Layout/Col/Col';
import { Head } from '@components/Layout/Head';
import { Row } from '@components/Layout/Row/Row';
import { LogosRow } from '@components/LogosRow/LogosRow';
import { ReviewsCarousel } from '@components/ReviewsCarousel/ReviewsCarousel';
import {
  reviewBanijay,
  reviewEsl,
  reviewSport1,
  reviewUdemy,
} from '@components/ReviewsCarousel/reviewsData';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';
import { routes } from '@routes';
import { cdnStats } from '@siteData';

import styles from './Home.module.scss';
import performanceImage from './performance.jpg';
import securityImage from './security.jpg';
import CacheRatioIcon from '@icons/cacheRatio.svg';
import IspConnectionSvg from '@icons/ispConnection.svg';
import PeakIcon from '@icons/peak.svg';
import VideoFeaturesIcon from '@icons/videoFeatures.svg';
import LongLinePath from '@images/Paths/longLine.svg';
import eslLogo from '@logos/Black/esl.svg';
import avastLogo from '@logos/Colored/avast.svg';
import banijayLogo from '@logos/Colored/banijay.svg';
import esetLogo from '@logos/Colored/eset.svg';
import sportOneLogo from '@logos/Colored/sport1.svg';
import udemyLogo from '@logos/Colored/udemy.svg';

interface IProps {
  title?: string;
  description?: string;
  noDescription?: boolean;
}

export const Home: React.FC<IProps> = ({ title, description, noDescription }) => (
  <>
    <Head
      canonical={routes.homePage}
      description={
        !noDescription
          ? description ??
            `${cdnStats.capacity} CDN with global coverage across ${cdnStats.continentsCount} continents. Accelerate your content delivery and reach your users from the edge with a 30-day free trial.`
          : undefined
      }
      title={title ?? 'Content Delivery Network (CDN)'}
    />
    <HomeHeader />
    <Section className={styles.wrapper} padding={{ top: 40, bottom: 40 }} theme="tiles">
      <LogosRow
        logos={[
          { src: banijayLogo, name: 'Banijay', width: 111, height: 32 },
          { src: eslLogo, name: 'ESL', width: 85, height: 29 },
          { src: udemyLogo, name: 'Udemy', width: 94, height: 34 },
          { src: avastLogo, name: 'Avast', width: 94, height: 29 },
          { src: esetLogo, name: 'Eset', width: 79, height: 32 },
          { src: sportOneLogo, name: 'Sport1', width: 92, height: 26 },
        ]}
        note="Trusted by"
      />
    </Section>

    <Section theme="tiles">
      <Text align="center" className="mb-3" color="gray900" display="block" size={44} tag="h2">
        {cdnStats.capacity} network with global backbone
      </Text>
      <Text align="center" className="mb-7" display="block" size={18} tag="p">
        Robust network with direct access to <strong>{cdnStats.ispConnected}+ local ISPs</strong>,{' '}
        <strong>{cdnStats.pnis} PNIs</strong> and{' '}
        <strong>{cdnStats.tier1TransitProviders} top-level transit providers</strong>.
      </Text>
      <ColumnsLayout className="mb-8">
        {[
          { icon: <PeakIcon />, title: cdnStats.trafficPeak, description: 'daily traffic peak' },
          {
            icon: <VideoFeaturesIcon />,
            title: cdnStats.videoPercentage,
            description: 'of traffic consists of video',
          },
          {
            icon: <IspConnectionSvg />,
            title: <>{cdnStats.ispConnected}+</>,
            description: 'ISPs connected directly',
          },
          {
            icon: <CacheRatioIcon />,
            title: cdnStats.avgCashHit,
            description: 'average cache hit ratio',
          },
        ].map(feature => (
          <FeaturedStat key={feature.description} {...feature} color="dark" theme="primary" />
        ))}
      </ColumnsLayout>
      <Row horizontalAlign="center">
        <Col xs="auto">
          <Button href={routes.network} theme="button" variant="secondary">
            CDN77 Network
          </Button>
        </Col>
      </Row>
    </Section>
    <Section padding={90}>
      <CdnFeature
        button={{
          href: routes.features,
          text: 'Features',
        }}
        highlights={[
          {
            title: 'Strong security measures',
            description: 'Always-on DDoS protection, TLS, Origin protection and more.',
          },
          {
            title: 'CDN77 Object Storage',
            description: 'S3-compatible API, 0 egress cost, and seamless integration with our CDN.',
          },
          {
            title: 'Sustained improvement',
            description:
              'In-house engineering teams ensure constant development and up-to-date technology.',
          },
        ]}
        image={performanceImage}
        title="Robust on every front"
        tooltip={{
          placement: 'top-start',
          position: { left: 50, top: 20 },
          text: 'We like our cables clean and tidy',
        }}
      />
      <CdnFeature
        button={{
          href: routes.videoCdn,
          text: 'Video CDN',
        }}
        highlights={[
          {
            title: 'Optimized VOD & Live video delivery',
            description:
              'Highest performance delivery of all major video formats (MP4/HLS/MPEG-DASH/CMAF) to all platforms and devices.',
          },
          {
            title: 'Dedicated video engineering team',
            description:
              'Intensive R&D and compatibility with latest video technologies thanks to a dedicated dev team.',
          },
          {
            title: 'Additional video features',
            description:
              'Encoding, transcoding, DVR & Timeshift, HTML5 player with audience analytics.',
          },
        ]}
        image={securityImage}
        title="Advanced video delivery"
        tooltip={{
          placement: 'top-start',
          position: { left: 15, top: 45 },
          text: "Honza from network operations. Loves food, hates being hungry. Though he's always hungry for ops. And he loves it.",
        }}
        isReversed
      />
    </Section>
    <Section id="performance" overflow="hidden" padding={{ top: 120, bottom: 170 }} theme="tiles">
      <Text align="center" className="mb-4" color="gray900" display="block" size={44} tag="h2">
        Performance tailored to your needs
      </Text>
      <Text align="center" className="mb-5" display="block" size={18} tag="p">
        Top performance is a must. Client-centric approach is what sets CDN77 apart.
      </Text>

      <AnimatedPath align="center" delayMs={0} durationMs={500} path={<LongLinePath />} />

      <CdnFeaturesTabs />
    </Section>
    <HomeUseCases />

    <Section overflow="hidden" padding={{ top: 170, bottom: 120 }}>
      <Text
        align="center"
        className="mb-7"
        color="gray900"
        display="block"
        size={30}
        tag="h2"
        weight="bold"
      >
        How satisfied clients are with CDN77
      </Text>
      <ReviewsCarousel data={[reviewUdemy, reviewEsl, reviewBanijay, reviewSport1]} />
    </Section>

    <CtaPanel className="mb-5" />
  </>
);
