import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';
import type { IImage } from '@components/Image/Image';
import { Image } from '@components/Image/Image';
import type { IProps as IImageTooltipProps } from '@components/ImageTooltip/ImageTooltip';
import { ImageTooltip } from '@components/ImageTooltip/ImageTooltip';
import { Col } from '@components/Layout/Col/Col';
import { Row } from '@components/Layout/Row/Row';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';

import styles from './CdnFeature.module.scss';

interface IProps {
  title: string;
  image: IImage;
  highlights: {
    title: string;
    description: string;
  }[];
  button: {
    href: string;
    text: string;
  };
  tooltip: Omit<IImageTooltipProps, 'children'>;
  isReversed?: boolean;
}

const cx = classNames.bind(styles);

export const CdnFeature: React.FC<IProps> = ({
  title,
  image,
  highlights,
  button,
  tooltip,
  isReversed,
}) => {
  const content = [
    <div key="text" className={cx('content', { isReversed })}>
      <Text align="left" className="mb-5" color="gray900" size={30} tag="h3" weight="bold">
        {title}
      </Text>
      <div className="mb-6">
        {highlights.map(({ title, description }, index) => (
          <div
            key={title}
            className={cx('highlight', 'mb-5')}
            style={{ transitionDelay: `${index * 100}ms` }}
          >
            <strong className={cx('title', 'mb-2')}>{title}</strong>
            <Paragraph>{description}</Paragraph>
          </div>
        ))}
      </div>
      <div className={styles.buttonWrapper}>
        <Button href={button.href} theme="button" variant="secondary">
          {button.text}
        </Button>
      </div>
    </div>,

    <ImageTooltip key="image" {...tooltip}>
      <Image alt="" className={styles.image} height={620} src={image} theme="rounded" width={575} />
    </ImageTooltip>,
  ];

  return (
    <Section padding={90} tag="div">
      <Row className={cx('row', { isReversed })} verticalAlign="center">
        {(isReversed ? content.reverse() : content).map((item, index) => (
          <Col key={index} lg={6}>
            {item}
          </Col>
        ))}
      </Row>
    </Section>
  );
};
