import React from 'react';

import { HalfNoiseBackground } from '@components/HalfNoiseBackground/HalfNoiseBackground';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';
import { UseCases } from '@components/UseCases/UseCases';

import styles from './HomeUseCases.module.scss';

export const HomeUseCases: React.FC = () => (
  <HalfNoiseBackground>
    <Section overflow="hidden" padding={0}>
      <Section
        borderRadius="rounded"
        className={styles.section}
        padding={90}
        tag="div"
        theme="dark"
        isPulled
      >
        <Text align="center" className="mb-7" color="white" display="block" size={44} tag="h2">
          Use cases
        </Text>
        <UseCases />
      </Section>
    </Section>
  </HalfNoiseBackground>
);
