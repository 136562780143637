import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';
import { Image } from '@components/Image/Image';
import { Col } from '@components/Layout/Col/Col';
import { Row } from '@components/Layout/Row/Row';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';
import useEventListener from '@hooks/useEventListener';

import styles from './HomeHeader.module.scss';
import doubleGlobeImage from './doubleGlobe.jpg';
import ArrowDown from '@icons/arrowDown.svg';

const cx = classNames.bind(styles);

export const HomeHeader: React.FC = () => {
  const [isMouseMoved, setIsMouseMoved] = React.useState(false);
  useEventListener('mousemove', () => {
    if (!isMouseMoved) {
      setIsMouseMoved(true);
    }
  });

  useEventListener('touchmove', () => {
    if (!isMouseMoved) {
      setIsMouseMoved(true);
    }
  });

  return (
    <Section className={styles.header} padding={{ top: 170, bottom: 0 }} tag="header">
      <Row className="mb-8">
        <Col lg={6}>
          <div>
            <Text
              align="left"
              className={cx('title', 'mb-5')}
              color="gray900"
              display="block"
              size={64}
              tag="h1"
              weight="bold"
            >
              Content Delivery Network.
            </Text>
            <Text className="mb-6" display="block" maxWidth={410} size={20} tag="p">
              Massive network scale, advanced video engineering, and stellar client service are just
              a few reasons to choose CDN77.
            </Text>

            <Button href="#performance" icon={<ArrowDown />} spacing="md" theme="link">
              What sets us apart
            </Button>
          </div>
        </Col>
        <Col lg={6}>
          <figure className={cx('globesWrapper', { play: isMouseMoved })}>
            <div className={styles.linesWrapper}>
              <div className={styles.line}></div>
              <div className={styles.verticalWrapper}>
                <div className={cx('circle', 'verticalCircle')} />
              </div>
              <div className={styles.horizontalWrapper}>
                <div className={cx('circle', 'horizontalCircle')} />
              </div>
            </div>
            <Image alt="" height={900} offset={0} src={doubleGlobeImage} width={900} priority />
          </figure>
        </Col>
      </Row>
    </Section>
  );
};
