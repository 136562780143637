import classNames from 'classnames/bind';
import React from 'react';

import { Text } from '@components/Text/Text';

import styles from './FeaturedStat.module.scss';

interface IProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  theme: 'primary' | 'secondary';
  color: 'dark' | 'light';
  layout?: 'columns' | 'auto';
  className?: string;
}

const cx = classNames.bind(styles);

export const FeaturedStat: React.FC<IProps> = ({
  icon,
  title,
  description,
  theme,
  color,
  layout = 'auto',
  className,
}) => (
  <div className={cx('wrapper', theme, color, layout, className)}>
    <div className={styles.iconWrapper}>{icon}</div>
    <div className={styles.content}>
      <Text
        align={theme === 'primary' ? 'center' : 'left'}
        className="mb-2"
        color={color === 'light' ? 'white' : 'gray900'}
        display="block"
        size={40}
        tag="strong"
      >
        {title}
      </Text>
      {description && (
        <Text
          align={theme === 'primary' ? 'center' : 'left'}
          color={color === 'light' ? 'white' : 'gray500'}
          opacity={color === 'light' ? 70 : 100}
          size={16}
          tag="p"
        >
          {description}
        </Text>
      )}
    </div>
  </div>
);
